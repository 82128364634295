import { useEffect, useCallback, useState, useRef } from 'react';

import { audio } from 'src/utils';

const useAudio = () => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef();

  const playAudio = useCallback(
    (url, onAudioEndCallback, asyncAudio = true) => {
      const handleAudioEndCallBack = () => {
        setIsAudioPlaying(false);

        if (onAudioEndCallback) {
          onAudioEndCallback();
        }
      };

      const shouldPlay = asyncAudio || !isAudioPlaying;
      audioRef.current = shouldPlay
        ? audio.triggerAudio(url, handleAudioEndCallBack)
        : null;
      setIsAudioPlaying(!audioRef.current?.paused);
    },
    [isAudioPlaying]
  );

  useEffect(() => {
    return () => {
      if (audioRef.current) audioRef.current.pause();
    };
  }, []);

  return [playAudio, isAudioPlaying, setIsAudioPlaying];
};

export default useAudio;
